import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogContent } from '@material-ui/core'
import _, { isNaN } from 'lodash'
import { Bar, Cell, ComposedChart, LabelList, ReferenceLine, ResponsiveContainer, YAxis } from 'recharts'
import classNames from 'classnames'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import {
  selectComparisonBoardProductTemplate,
  selectComparisonBoardProducts,
} from '../../../../state/comparisonBoard/selectors'
import nodeDefinitionTypeMap from '../../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import { hasChildrenCriterionAnyModelChange, hasChildrenCriterionModelChange } from '../../../../services/modelChange'

function ChartModal({
  environment,
  product,
  template,
  modelChangeData,
  modelChangeItems,
  productsData,
  onClose,
  open,
  texts,
}) {
  // Modal Title
  const brandName = product && _.find(product.props, { slug: 'brand' }).value.body[environment.defaultLang]
  const modelName = product && _.find(product.props, { slug: 'model' }).value.body[environment.defaultLang]
  const title = product && `${brandName} - ${modelName}`

  // Chart Colors
  const colors = {
    negative: 'rgba(221, 24, 45, 0.3)',
    positive: 'rgba(11, 106, 198, 0.5)',
  }

  // Get Family Node Definitions
  const [familyNodeDefinitions, setFamilyNodeDefinitions] = useState([])

  useEffect(() => {
    if (template && familyNodeDefinitions.length === 0) {
      const families = _.filter(template.node_definitions, item => item.type === nodeDefinitionTypeMap.family)
      // const familiesHasModelChange = _.filter(families, nodeDef => hasChildrenCriterionAnyModelChange(nodeDef))
      setFamilyNodeDefinitions(_.values(families))
    }
  }, [template])

  // Get Label for Model Change scores list
  const getLabel = (nodeDef, modelChangeItem) => {
    if (nodeDef && modelChangeItem && template) {
      const name = _.get(nodeDef, ['name', environment.defaultLang], '')
      const parentNodeDefinition = _.find(template.node_definitions, item => item.id === nodeDef.parent_id)
      const parentName = _.get(parentNodeDefinition, ['name', environment.defaultLang], '')
      const parentNameFirstWord = parentName.replace(/ .*/, '')

      return `${parentNameFirstWord} ${name} \n ${modelChangeItem.value}`
    }

    return ''
  }

  // Get reference product for Model Change score calc
  const referenceProduct = useMemo(() => {
    return productsData.find(prod => prod.reference)
  }, [])

  // Get Model Chagne Score
  const getScore = (nodeDef, modelChangeItem) => {
    const node = _.find(product.nodes, _n => _n.node_definition_id === nodeDef.id)
    const referenceNode = _.find(referenceProduct?.nodes, _n => _n.node_definition_id === nodeDef.id)

    const modelChangeScore = node?.model_change?.find(score => score.model_change_item_id === modelChangeItem.id)
    const modelChangeScoreValue = modelChangeScore?.normalized_score
    const referenceModelChangeScore = referenceNode?.model_change?.find(
      score => score.model_change_item_id === modelChangeItem.id
    )
    const referenceModelChangeScoreValue = referenceModelChangeScore?.normalized_score
    const score = modelChangeScoreValue - referenceModelChangeScoreValue
    return !isNaN(modelChangeScoreValue) ? Math.round(score * 10) / 10 : ''
  }

  // Get families with model change data for SCORES LIST
  const familiesHasModelChange = useMemo(() => {
    // filter families by model change data
    const familyFiltered = _.filter(familyNodeDefinitions, nodeDef =>
      hasChildrenCriterionAnyModelChange(template, nodeDef, modelChangeData)
    )
    return familyFiltered
  }, [familyNodeDefinitions, modelChangeData, template])

  // Get product model change SCORES LIST
  const productModelChangeScores = useMemo(() => {
    let scores = []

    if (familiesHasModelChange)
      _.each(familiesHasModelChange, familyNodeDef => {
        // get score for each model change item in family
        _.each(Object.values(modelChangeItems), modelChangeItem => {
          // check if model change item has score in family
          if (hasChildrenCriterionModelChange(template, familyNodeDef, modelChangeData, modelChangeItem?.id)) {
            // add score
            const modelChangeScore = {
              label: getLabel(familyNodeDef, modelChangeItem),
              score: getScore(familyNodeDef, modelChangeItem),
            }
            scores.push(modelChangeScore)
          }
        })
      })

    // reorder score ascendent
    scores = _.orderBy(scores, 'score', 'desc')

    // aggregate for watherfall chart
    scores = scores.map((score, index) => {
      // aggregate all previews scores
      const allPrevScores = scores.slice(0, index)
      const prevScores = allPrevScores.map(prevScore => prevScore.score)
      const prevScoreSum = _.sum(prevScores)
      const aggregatedScore = prevScoreSum + score.score

      return {
        ...score,
        aggregatedScore,
        prevScoreSum,
      }
    })

    return scores
  }, [familiesHasModelChange])

  // chart renderized scores
  const formatScore = value => {
    if (value > 0) return `+${value}`
    if (value === 0) return '0'
    return value
  }

  const renderScore = props => {
    const { x, y, width, height, value } = props

    let yPosition = y + 15
    if (Math.abs(value) < 20) yPosition = y - 15
    if (value < 0) {
      yPosition = y + height + 15
      if (Math.abs(value) < 20) yPosition = y + height - 15
    }

    return (
      <text
        x={x + width / 2}
        y={yPosition}
        fontSize={13}
        fontWeight={500}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {formatScore(value)}
      </text>
    )
  }

  // chart renderized labels
  const renderLabel = props => {
    const { x, y, width, height, value } = props
    const scoreData = _.find(productModelChangeScores, { label: value })

    let yPosition = y - 35
    if (Math.abs(scoreData?.score) < 20) yPosition = y + 20
    if (scoreData?.score < 0) {
      yPosition = y + height - 10
      if (Math.abs(scoreData?.score) < 20) yPosition = y + 30
    }

    return (
      <g>
        <foreignObject
          className={classNames('model-change-chart-modal__label', {
            'model-change-chart-modal__label--negative': scoreData?.score < 0,
          })}
          x={x + width / 2}
          y={yPosition}
          width={width}
          height={50}
        >
          <span width={width}>{value}</span>
        </foreignObject>
      </g>
    )
  }

  return (
    <>
      <Dialog PaperProps={{ className: 'model-change-chart-modal' }} onClose={onClose} open={open}>
        <div className="model-change-chart-modal__header">
          <div>{title}</div>
          <button type="button" onClick={onClose} className="model-change-chart-modal__close-button">
            {texts.close}
          </button>
        </div>
        <DialogContent dividers>
          <div className="model-change-chart-modal__chart-container">
            <div className="model-change-chart-modal__chart">
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                  width={900}
                  height={300}
                  data={productModelChangeScores}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <YAxis padding={{ top: 30, bottom: 30 }} hide />
                  <ReferenceLine y={0} stroke="rgba(0, 0, 0, 0.2)" strokeWidth={1} />
                  <Bar dataKey="prevScoreSum" stackId="a" fill="transparent" />
                  <Bar dataKey="score" stackId="a">
                    {productModelChangeScores.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.score > 0 ? colors.positive : colors.negative} />
                    ))}
                    <LabelList dataKey="label" fill="black" content={renderLabel} />
                    <LabelList dataKey="score" fill="black" content={renderScore} />
                  </Bar>
                </ComposedChart>
              </ResponsiveContainer>
            </div>
            <table className="model-change-chart-modal__table">
              <tbody>
                {productModelChangeScores.map((score, index) => (
                  <tr key={index}>
                    <td className="model-change-chart-modal__table__key">{score.label}</td>
                    <td className="model-change-chart-modal__table__value">{formatScore(score.score)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    template: selectComparisonBoardProductTemplate(state),
    productsData: selectComparisonBoardProducts(state),
    modelChangeData: state.renaultProjectMode.comparisonBoard.modelChangeData,
    modelChangeItems: state.renaultProjectMode.comparisonBoard.modelChangeItems,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ChartModal)

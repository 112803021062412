import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import ParentContextualMenu from '../../Common/ContextualMenu'
import { RPM_COMPARISON_BOARD_COLORS } from '../../../../consts/comparisonBoard'
import { POPOVER_POSITION_BOTTOM_RIGHT } from '../../Common/Popover'
import scoringModeMap from '../../../../../../components/scoring_tree/helper/scoringModeMap'
import styles from '../../../../styles/ContextualMenu.module.scss'
import { isProductUpdatable } from '../../../../services/comparisonBoard'
import { selectEditedNodesByProductId } from '../../../../state/comparisonBoard/selectors'
import EditIcon from '../../../../../../assets/svg/edit-scoring-icon.svg'
import SaveIcon from '../../../../../../assets/svg/download-pq.svg'
import ProductIdIcon from '../../../../../../assets/svg/edit-scoring.svg'
import ResetIcon from '../../../../../../assets/svg/update-icon.svg'
import Dialog from '../../Common/Dialog'
import ExportScoring from '../../../../../../assets/svg/export-scoring.svg'
import apiEndPoints from '../../../../../../../va-corejs-v3/api/apiEndPoints'

function ContextualMenu({
  open,
  product,
  onClickAway,
  anchorEl,
  updateComparisonBoardProductAttributes,
  saveComparisonBoardProduct,
  resetComparisonBoardProduct,
  editedNodes,
  authentication,
  texts,
  environment,
  device,
  processSyncQueue,
  updateExportData,
}) {
  const { color: currentColor, board_locked: boardLoacked } = product
  const {
    canViewProductScoring: userCanViewProductScoring,
    canEditProduct: userCanEditProduct,
    canExport: userCanExport,
  } = authentication.user.parsedPermissions

  const [isLoading, setIsLoading] = useState(false)
  const [openResetDialog, setOpenResetDialog] = useState(false)
  const [openSaveDialog, setOpenSaveDialog] = useState(false)

  const brandName = product && _.find(product.props, { slug: 'brand' }).value.body[environment.defaultLang]
  const modelName = product && _.find(product.props, { slug: 'model' }).value.body[environment.defaultLang]
  const productName = product && `${brandName} ${modelName}`

  // used to avoid to set some state if menu disappear
  // caused by the choise to use clickAway and parent state to unmount menu
  const componentWillUnmount = useRef(false)

  useEffect(() => {
    return () => {
      componentWillUnmount.current = true
    }
  }, [])

  const handleEditScoringClick = () => {
    window.open(`/scoring?id=${product.product_id}`, '_blank')
  }

  const handleProductIdClick = () => {
    window.open(`/scoring?id=${product.product_id}&view=${scoringModeMap.product}`, '_blank')
  }

  const handleColorClick = value => {
    const newValue = currentColor === value ? null : value
    updateComparisonBoardProductAttributes(product.product_id, { color: newValue })
  }

  const handleExportScoringClick = () => {
    updateExportData(apiEndPoints.export_scoring.replace('{scoringId}', product.product_id))
  }

  const handleSaveProductClick = async () => {
    setOpenSaveDialog(true)
  }

  const handleClickDialogSave = async () => {
    setIsLoading(true)
    await saveComparisonBoardProduct(product.product_id)
    if (device.isOnline) {
      processSyncQueue()
    }
    if (!componentWillUnmount.current) {
      setIsLoading(false)
      setOpenSaveDialog(false)
      onClickAway()
    }
  }

  const handleResetProductClick = async () => {
    setOpenResetDialog(true)
  }

  const handleClickDialogReset = async () => {
    setIsLoading(true)
    await resetComparisonBoardProduct(product.product_id)
    if (!componentWillUnmount.current) {
      setIsLoading(false)
      setOpenResetDialog(false)
      onClickAway()
    }
  }

  const handleClose = () => {
    if (onClickAway && !openResetDialog && !openSaveDialog) onClickAway()
  }

  if (!open) {
    return null
  }

  const colorsItem = (
    <div className={styles.ContextualMenu_item_button}>
      <div className={styles.ContextualMenu_item_title}>{texts.color}</div>
      <div className={styles.ContextualMenu_item_colours}>
        {RPM_COMPARISON_BOARD_COLORS.map(color => (
          <button
            key={color.value}
            type="button"
            className={classNames('comparison-board-color-button', `comparison-board-color-button--${color.value}`, {
              'comparison-board-color-button--active': color.value === currentColor,
            })}
            title={color.label}
            onClick={() => handleColorClick(color.value)}
          />
        ))}
        <button
          type="button"
          className={classNames('comparison-board-color-button', `comparison-board-color-button--none`, {
            'comparison-board-color-button--active': !currentColor,
          })}
          onClick={() => handleColorClick()}
        />
      </div>
    </div>
  )

  return (
    <>
      <ParentContextualMenu
        onClickAway={handleClose}
        anchorEl={anchorEl}
        margin={0}
        direction="left"
        position={POPOVER_POSITION_BOTTOM_RIGHT}
        fixed={false}
        items={[
          {
            label: texts.edit_scoring,
            disabled: !userCanViewProductScoring || isLoading || boardLoacked,
            onClick: handleEditScoringClick,
            icon: <EditIcon />,
          },
          {
            label: texts.product_id,
            disabled: !userCanViewProductScoring || isLoading || boardLoacked,
            onClick: handleProductIdClick,
            icon: <ProductIdIcon />,
          },
          {
            label: texts.scoring_actions_export_scoring,
            disabled: !userCanExport || isLoading,
            onClick: handleExportScoringClick,
            icon: <ExportScoring />,
          },
          {
            label: texts.save_product,
            disabled: !userCanEditProduct || !isProductUpdatable(product) || editedNodes.length === 0 || isLoading,
            onClick: handleSaveProductClick,
            icon: <SaveIcon />,
          },
          {
            label: texts.reset_product,
            disabled: editedNodes.length === 0 || isLoading,
            onClick: handleResetProductClick,
            icon: <ResetIcon />,
          },
          {
            element: colorsItem,
            className: 'comparison-board-product-card-contextual-menu-item',
          },
        ]}
      />
      <Dialog
        open={openResetDialog}
        setOpen={setOpenResetDialog}
        onConfirm={handleClickDialogReset}
        closeOnConfirm={false}
        title={`${texts.reset}  ${productName}`}
        text={`${texts.are_you_sure_reset_changes_to} ${productName} ?`}
        confirmButtonText={texts.reset}
        loading={isLoading}
      />
      <Dialog
        open={openSaveDialog}
        setOpen={setOpenSaveDialog}
        onConfirm={handleClickDialogSave}
        closeOnConfirm={false}
        title={`${texts.save}  ${productName}`}
        text={`${texts.are_you_sure_push_changes_to} ${productName} ?`}
        confirmButtonText={texts.save}
        loading={isLoading}
      />
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    device: state.device,
    authentication: state.authentication,
    texts: state.texts.values,
    environment: state.environment,
    editedNodes: selectEditedNodesByProductId(state, ownProps.product.product_id),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ContextualMenu)

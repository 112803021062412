import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import classNames from 'classnames'
import Panel from './Panel'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardProducts,
  selectComparisonBoardReferenceProduct,
} from '../../../state/comparisonBoard/selectors'
import ProductCard from './ProductCard'
import DragHandleElement from './ProductCard/Buttons/DragHandle'

const DragHandle = SortableHandle(() => <DragHandleElement />)

const SortableItem = SortableElement(({ product, sticky }) => (
  <th
    className={classNames('comparison-board-table__product-card-cell ', {
      'comparison-board-table__product-card-cell--sticky': sticky,
    })}
  >
    <ProductCard product={product} sticky={sticky} />
    <DragHandle />
  </th>
))

const SortableList = SortableContainer(({ products, referenceProduct, sticky }) => {
  return (
    <tr
      className={classNames('comparison-board-table__header-row', {
        'comparison-board-table__header-row--sticky': sticky,
      })}
    >
      <td className="comparison-board-table__header-cell panel-cell">
        <Panel sticky={sticky} />
      </td>

      {!referenceProduct &&
        products.map((product, index) => (
          <SortableItem
            index={index}
            product={product}
            key={`product-th-${product.product_id}-${JSON.stringify(product.score_panel)}-${index}`}
            sticky={sticky}
          />
        ))}

      {
        // VAV3-1144: Reference product is always the first one and it's not sortable,
        // the rest of the products are sortable
      }

      {referenceProduct &&
        products.map((product, index) => (
          <Fragment key={`product-th-${product.product_id}-${JSON.stringify(product.score_panel)}-${index}`}>
            {index === 0 && (
              <th
                className={classNames('comparison-board-table__product-card-cell ', {
                  'comparison-board-table__product-card-cell--sticky': sticky,
                })}
              >
                <ProductCard product={product} sticky={sticky} />
              </th>
            )}
            {index !== 0 && (
              <SortableItem
                index={index}
                product={product}
                key={`product-th-${product.product_id}-${JSON.stringify(product.score_panel)}-${index}`}
                sticky={sticky}
              />
            )}
          </Fragment>
        ))}
    </tr>
  )
})

function HeaderRow({ productsData, sortComparisonBoardProducts, referenceProduct }) {
  // Get sticky status
  const stickyTrashold = 120
  const [sticky, setSticky] = useState(window.scrollY >= stickyTrashold)

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY >= stickyTrashold)
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    sortComparisonBoardProducts(oldIndex, newIndex)
  }

  return (
    <SortableList
      products={productsData}
      referenceProduct={referenceProduct}
      onSortEnd={handleSortEnd}
      axis="x"
      useDragHandle
      sticky={sticky}
    />
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    productsData: selectComparisonBoardProducts(state),
    referenceProduct: selectComparisonBoardReferenceProduct(state),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(HeaderRow)

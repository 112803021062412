import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import MenuButton from './Buttons/MenuButton'
import ReferenceButton from './Buttons/ReferenceButton'
import RemoveButton from './Buttons/RemoveButton'
import ContextualMenu from './ContextualMenu'
import ProductBrand from './ProductBrand'
import ProductCover from './ProductCover'
import ScorePanel from './ScorePanel'
import IconLock from '../../../../../../assets/svg/lock_product_menu_black.svg'
import ProductName from './ProductName'
import { selectComparisonBoardOption, selectEditedNodesByProductId } from '../../../../state/comparisonBoard/selectors'
import Chart from '../ModelChange/Chart'
import { RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE } from '../../../../consts/comparisonBoard'

const classNames = require('classnames')

function ProductCard({ product, updateComparisonBoardProductAttributes, modelChangeOption, editedNodes, sticky }) {
  const [contextualMenuAnchorEl, setContextualMenuAnchorEl] = useState(null)
  const contextualMenuOpen = Boolean(contextualMenuAnchorEl)
  const { color } = product

  const handleLock = () => {
    updateComparisonBoardProductAttributes(product.product_id, {
      board_locked: !product.board_locked,
    })
  }

  return (
    <div
      className={classNames('comparison-board__product-card-wrapper', {
        'comparison-board__product-card-wrapper--enabled': editedNodes.length > 0,
        'comparison-board__product-card-wrapper--model-change': modelChangeOption,
      })}
    >
      <div className={classNames('comparison-board__product-card')}>
        <div className="comparison-board-product-card__header">
          {!sticky && <ProductCover productId={product.id} overlayColor={color} />}

          <div className="comparison-board-product-card__name-wrapper">
            <ProductBrand productId={product.product_id} />
            <div className="comparison-board-product-card__name">
              <ProductName productId={product.product_id} />
            </div>
          </div>
        </div>

        {!sticky && (
          <div className="comparison-board-product-card__body">
            <ScorePanel scorePanel={product.score_panel} />
          </div>
        )}

        <RemoveButton productId={product.product_id} />

        {!product.is_configuration && (
          <div className="comparison-board-product-card__lock comparison-board-product-card__lock--disabled">
            <IconLock />
          </div>
        )}
        {product.is_configuration && (
          <button
            className={classNames('comparison-board-product-card__lock', {
              'comparison-board-product-card__lock--active': product.board_locked,
            })}
            onClick={() => handleLock()}
            type="button"
          >
            <IconLock />
          </button>
        )}

        <ReferenceButton productId={product.product_id} active={product.reference} />
        <MenuButton onClick={e => setContextualMenuAnchorEl(e.currentTarget)} />

        <ContextualMenu
          open={contextualMenuOpen}
          onClickAway={() => setContextualMenuAnchorEl(null)}
          product={product}
          anchorEl={contextualMenuAnchorEl}
        />

        <Chart product={product} />
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    environment: state.environment,
    editedNodes: selectEditedNodesByProductId(state, ownProps.product.id),
    modelChangeOption: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProductCard)

import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectProjectById,
  selectProjectConfigurationCategoryById,
  selectProjectMappingByConfigurationCategory,
} from '../../../state/projects/selectors'
import Button from '../Common/Button'
// VAV3-1092: disable expand all feature
// import ExpandAllSwitcher from './OptionsSwitchers/ExpandAll'
import FullDataSwitcher from './OptionsSwitchers/FullData'
import DifferencesSwitcher from './OptionsSwitchers/Differences'
import BestWorstSwitcher from './OptionsSwitchers/BestWorst'
import SearchProductsModal from '../SearchProductsModal'
import ModelChangeToggle from './ModelChangeControls/ModelChangeToggle'
import ModelChangeSelect from './ModelChangeControls/ModelChangeSelect'
import Dialog from '../Common/Dialog'
import SaveIcon from '../../../../../assets/svg/save.svg'
import ArrowBack from '../../../../../assets/svg/arrow-back.svg'

function Panel({
  project,
  configurationCategory,
  fetchProducts,
  mapProductsToProject,
  mapping,
  saveComparisonBoardProducts,
  processSyncQueue,
  device,
  calculateComparisonBoardProductsScore,
  texts,
  sticky,
}) {
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openSaveDialog, setOpenSaveDialog] = useState(false)
  const [confirmSaveDialog, setConfirmSaveDialog] = useState(false)

  const preSelectedIds = _.map(mapping, item => item.product_id)

  const handleOpenSearchModal = e => {
    e.stopPropagation()
    setSearchModalOpen(true)
  }

  const handleConfirm = async ids => {
    setIsLoading(true)

    // Get the products by id with the scoring tree included
    const onlyNewIds = _.difference(ids, preSelectedIds)
    await fetchProducts({ _id: onlyNewIds }, ['nodes', 'media', 'nodes.comments'])

    await mapProductsToProject(ids, project.id, configurationCategory.id)

    // Calculate products scoring
    await calculateComparisonBoardProductsScore()

    // Remove the configuration from the project if it is selected
    /*
    const configurationSelectedIds = _.filter(
      products,
      product => product.is_configuration && _.includes(ids, product.id)
    )?.map(item => item.id)

    const benchmarkSelectedIds = _.filter(
      products,
      product => !product.is_configuration && _.includes(ids, product.id)
    )?.map(item => item.id)

    // remove configuration from project list
  
   _.each(configurationSelectedIds, id => {
      removeProductFromProject(id, project.id)
      // to remove also product
    })

    // remove benchmark from project list
    _.each(benchmarkSelectedIds, id => {
      removeProductFromProject(id, project.id)
    })
    */

    setIsLoading(false)
  }

  const handleSaveBoard = async () => {
    setOpenSaveDialog(true)
  }

  const handleClickDialogSave = async () => {
    setConfirmSaveDialog(true)
  }

  const handleClickDialogConfirmSave = async () => {
    setIsLoading(true)
    await saveComparisonBoardProducts()
    if (device.isOnline) {
      processSyncQueue()
    }
    setIsLoading(false)
    setOpenSaveDialog(false)
    setConfirmSaveDialog(false)
  }

  const configurationCategoryName = configurationCategory.name ?? texts.new_configuration_category_title
  const snakeCaseTypeSlug = _.replace(configurationCategory.type, '-', '_')
  const configurationType = texts[`configuration_category_${snakeCaseTypeSlug}_title`]

  return (
    <>
      <div className="comparison-board-table__panel">
        {!sticky && (
          <>
            <div className="panel__project-name">
              <a href={`/project-mode/project/edit?id=${project.id}`} rel="noreferrer">
                <span className="panel__project-name__icon">
                  <ArrowBack />
                </span>
                <span className="panel__project-name__text">{project.name ?? texts.project_name}</span>
              </a>
            </div>
            <div className="panel__configuration-category-name">{configurationCategoryName}</div>

            <div className="panel__button-wrapper">
              <Button label="Add scoring" fullWidth onClick={handleOpenSearchModal} disabled={isLoading} />
            </div>

            <div className="panel__button-wrapper">
              <Button
                label={texts.save_board}
                fullWidth
                onClick={handleSaveBoard}
                color="secondary"
                disabled={isLoading}
              />
            </div>
          </>
        )}

        <div className="panel__options-container">
          {/* 
          VAV3-1092: disable expand all feature
          <ExpandAllSwitcher /> 
          */}
          <FullDataSwitcher />
          <DifferencesSwitcher />
          <BestWorstSwitcher />
        </div>
        <div className="panel__model-change-controls">
          <ModelChangeToggle />
          <ModelChangeSelect width={110} />
        </div>
      </div>

      {project && searchModalOpen && (
        <SearchProductsModal
          title={project.name ?? texts.project_name}
          project={project}
          onClose={() => setSearchModalOpen(false)}
          onConfirm={handleConfirm}
          preSelectedIds={preSelectedIds}
          isSyncing={isLoading}
        />
      )}

      <Dialog
        open={openSaveDialog}
        setOpen={setOpenSaveDialog}
        onCancel={() => {
          setConfirmSaveDialog(false)
        }}
        onConfirm={confirmSaveDialog ? handleClickDialogConfirmSave : handleClickDialogSave}
        closeOnConfirm={false}
        title={`${texts.save}  ${configurationType}`}
        text={
          confirmSaveDialog
            ? `${texts.are_you_really_sure_to_save} ${configurationCategoryName}?`
            : `${texts.are_you_sure_to_save} ${configurationCategoryName}? ${texts.all_changes_will_be_published_configurations}`
        }
        confirmButtonText={texts.save}
        textColor={confirmSaveDialog && 'red'}
        loading={isLoading}
        icon={<SaveIcon />}
        width={465}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    project: selectProjectById(state, state.renaultProjectMode.comparisonBoard.projectId),
    configurationCategory: selectProjectConfigurationCategoryById(
      state,
      state.renaultProjectMode.comparisonBoard.projectId,
      state.renaultProjectMode.comparisonBoard.configurationCategoryId
    ),
    mapping: selectProjectMappingByConfigurationCategory(
      state,
      state.renaultProjectMode.comparisonBoard.projectId,
      state.renaultProjectMode.comparisonBoard.configurationCategoryId
    ),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(Panel)

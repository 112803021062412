import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardOption,
  selectComparisonBoardProductTemplate,
} from '../../../state/comparisonBoard/selectors'
import BodyRow from './BodyRow'
import { RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE } from '../../../consts/comparisonBoard'
import ModelChange from './ModelChange'

function BodyRows({ template, modelChangeOption }) {
  const [rootNodeDefinitions, setRootNodeDefinitions] = useState([])

  useEffect(() => {
    if (template && rootNodeDefinitions.length === 0) {
      const roots = _.filter(template.node_definitions, item => item.parent_id === null)
      setRootNodeDefinitions(_.values(roots))
    }
  }, [template])

  return (
    <tbody className="comparison-board-table__body">
      {!modelChangeOption && (
        <>
          {rootNodeDefinitions.map(item => (
            <BodyRow nodeDefinition={item} key={`row-${item.id}`} />
          ))}
          <tr className="comparison-board-table__body-row comparison-board-table__end-row" />
        </>
      )}

      {modelChangeOption && <ModelChange />}
    </tbody>
  )
}

const mapStateToProps = state => {
  return {
    template: selectComparisonBoardProductTemplate(state),
    modelChangeOption: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE),
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(BodyRows)
